import './footer.css'
import { AiOutlineCopyrightCircle } from 'react-icons/ai'

const Footer = () => {
    return (
        <footer>
            
        </footer>
    )
}

export default Footer;